.languageModal {
  display: flex !important;
  justify-content: space-evenly !important;
  height: 40vh;
  align-items: normal;
  flex-direction: column;
  margin: auto;
  text-align: center;
  color: black;
}
.instructionsModal {
  display: flex !important;
  justify-content: center !important;
  height: 40vh;
  align-items: normal;
  flex-direction: column;
  margin: auto;
  text-align: center;
  color: black;
}

.languageModal h1 {
  font-size: x-large;
}

.languageButton {
  background-color: var(--color-green) !important;
  font-size: x-large !important;
}

.instructionsButton {
  background-color: var(--color-green) !important;
  font-size: large !important;
}
