.textalign.left {
  text-align: left;
}

.column.left {
  width: 30%;
  float: left;
}

.column.right {
  width: 30%;
  float: right;
}

.cvButton {
  height: 90px;
}

/* FONTS ******************************************************** */

p {
  font-family: "Space Grotesk", sans-serif;
  font-size: larger;
}

/* MEDIA QUERY UNDER 640 PX *************************************+ */

@media (max-width: 640px) {
  .banner p {
    font-size: inherit;
  }

  .skill.Item {
    max-width: 90% !important;
  }

  .project {
    max-width: 90% !important;
  }
}

@media (max-width: 680px) {
  .project a {
    font-size: x-large !important;
  }
}

/* BANNER ******************************************************** */

.banner {
  background-color: var(--main-color2);
  color: var(--titles-color2);
  vertical-align: middle;
  margin: auto;
  margin-bottom: 50px;
  width: 100%;
  max-width: 800px;
  height: auto;
  padding: 30px 20px;
  border-radius: 2em;
  text-align: center;
  display: flow-root;
  /* box-shadow: #3f3f3f 0px 20px 30px -10px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 30px;
}

.banner h1 {
  font: 18px "Inter", sans-serif; /* El EDUCACIÓN y EXPERIENCIA*/
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.banner h2 {
  font-family: "Inter", sans-serif;
  margin-bottom: 12px;
}

.banner p {
  line-height: 30px;
}

.banner .button a:hover,  /*EDITAR*/
.banner .button a:active {
  background-color: white;
  /* color: var(--secondary-color2); */
  color: var(--secondary-color);
}
.banner .button a {
  padding: 10px;
  border: white 2px solid;
  border-radius: 1em;
  color: white;
}

/* Banner color ------------------------------ */
.banner.color {
  /* background-color: var(--secondary-color2); */
  background-color: var(--secondary-color);
  color: white;
}

.banner.color a {
  color: white;
  opacity: 0.8;
  font-weight: 900;
  font-size: initial;
}

.banner.color a:hover,
.banner.color a:focus {
  transition: all 0.3s;
  opacity: 1;
}
/* Banner color ------------------------------ */

/* Banner border ------------------------------ */
.banner.border {
  background-color: transparent;
  /* border: var(--secondary-color2) 10px solid; */
  border: var(--secondary-color) 10px solid;
  color: var(--titles-color2);
}

.banner.border a {
  opacity: 0.8;
  font-weight: 900;
}
/* Banner border ------------------------------ */

.banner.topmargin {
  margin-top: 40px;
}

/* OTRAS COSAS DE ABOUT ****************************************** */

.banner.about {
  text-align: left;
}
/* OTRAS COSAS DE RESUME ****************************************** */

.banner.education {
  text-align: left;
}

.banner .underlined {
  border-bottom: 3px solid var(--titles-color2);
  padding-bottom: 6px;
}

.banner.color .underlined {
  border-bottom: 3px solid white;
  padding-bottom: 6px;
}

.school {
  font-family: "Playfair Display", serif; /* El nombre de la institución*/
  font-style: italic;
  color: var(--school-color2);
  margin-bottom: 14px;
  margin-top: 5px;
}

.school span {
  margin-right: 5px;
  margin-left: 5px;
}

.date {
  font-family: "Inter", sans-serif; /* la fecha de estudios */
  font-style: normal;
  font-weight: 400;
  margin-top: 6px;
}

/* SKILL ******************************************************** */

.skillsSection {
  max-width: 800px;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill.Item {
  text-align: center;
  max-width: 50%;
  min-width: 290px;
  margin-bottom: 2.5em;
  padding: 0px 5%;
  display: inline-block;
  vertical-align: top;
}

.skill h3 {
  color: var(--titles-color2);
  font-size: x-large;
}

.skill p {
  color: var(--color-grey);
}

/* PROJECT ******************************************************* */

.projectSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project {
  /* text-align: center;
  margin-top: 2.5em;
  margin-bottom: auto;
  padding: 0px 5%;
  max-width: 50%;
  min-width: 200px;
  display: inline-block; */

  margin-top: 2.5em;
  margin-bottom: auto;
  text-align: center;
  max-width: 50%;
  min-width: 230px;
  display: inline-block;
}

.project img {
  max-width: 80%;
  margin-top: 20px;
  border-radius: 1em;
}

.project a {
  color: var(--titles-color2);
  font-size: xx-large;
  font-weight: 900;
  padding: 10px;
  border-radius: 1em;
}

.project a:hover {
  color: white;
  /*background-color: var(--secondary-color2); */
  background-color: var(--secondary-color);
}

/* CONTACT FORM ******************************************************* */

.contact.form label {
  color: white !important;
  font-size: medium !important;
  text-align: left;
}

.contact.form button {
  font-size: medium !important;
  display: inline-block;
  background-color: white !important;
  color: black !important;
}
