@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

:root {
  --main-color: #dee8f1;
  --secondary-color: #3b7aa4;
  --tertiary-color: #a2e0e0;
  --color-grey: #4d4d4d;
  --color-green: #09d3ac;

  --main-color2: #dee8f1;
  --secondary-color2: #6f49f6;
  --tertiary-color2: #a492f8;
  --titles-color2: #403335;
  --school-color2: #5e5455;
  --text-color2: #908d92;
}

/* El boton de aboutme mas largo */
.AboutMe {
  width: 85px;
}

/* Los input de gas */
.InputSize {
  width: 200px;
  padding: 3px;
}

/* Fuente texto SingTheWord */
.Text {
  font-family: "RocknRoll One", sans-serif;
}

.Centered {
  margin: 5% auto;
  width: fit-content;
}
